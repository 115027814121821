/*---- NAn. Catalogo  -------*/
.containerFlip {
    width: 100% !important;
    margin: 0 auto;
    overflow: hidden !important;
    border: 1px solid gray;
    padding: 0;
    height: 100vh !important;
    max-height: 850px !important;
}

.demoPage {
    overflow: hidden;
    border: 1px solid black;
    height: 100% !important;
}

.demoPage .wrap {
    border: 1px solid red;
    height: 100% !important;
    width: auto;
    overflow: hidden;
}

.demoPage img {
    height: auto !important;
    max-height: 100vh !important;
}

// .stf__block{

// }