/*------ 23. Puntos de Venta Page  ------*/

.map {
  position: relative;
  height: 620px;
  @media #{$xs-layout} {
    height: 480px;
  }
}

.mapDiv {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 620px;
  @media #{$xs-layout} {
    height: 480px;
  }
}

.loadingMapDiv{
  position: absolute;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(201, 201, 201);
  width: 100%;
  height: 620px;
  @media #{$xs-layout} {
    height: 480px;
  }
}

.loadingMapDiv img{
  align-content: center;
}

.map-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

.leaflet-div-icon {
  background: none !important; /* Eliminar fondo */
  border: none !important;     /* Eliminar borde */
  box-shadow: none !important; /* Quitar sombras si existen */
}

/* Estilos para bookmarks */

.bookmarks-container {
  position: absolute;
  bottom: 30px;
  left: 10px;
  z-index: 99;
  max-height: 400px;
  width: 302px;
  overflow: hidden;
  @media #{$xs-layout} {
    bottom: -28px;
    left: 0px;
    width: 100%;
    max-height: 240px;  
  }
  .accordion {
    bottom: 30px;
    &-item {
      max-height: 400px;
      width: 300px;
      @media #{$xs-layout} {
        width: 100%;
        max-height: 240px;  
      }
    }  
    &-header {
      width: 300px;
      padding: 0px;
      button{
        background-color: $theme-color;
        color: #fff;
        padding: 3px 15px;
      }
      button::after{
        color: #fff !important;
      }
      button:focus{
        outline: none;
        box-shadow: 0 0 0px #fff;
      }
      @media #{$xs-layout} {
        width: 100%;  
      }
    }  
    &-body {
      height: 300px;
      // overflow-x: scroll;
      overflow-y: scroll;
      button{
        padding: 8px 12px;
        width: 100%;
        background-color: transparent;
        border: none;
        text-align: left;
        cursor: pointer; 
        @media #{$xs-layout} {
          padding: 18px 24px;  
        }   
        &:hover {
          background-color: #0080c02c;
          border-radius: 25px;
        }
        &.selected {
          background-color: $theme-color;
        }
      }     
    }    
  }
}

.title-container{
  padding-bottom: 20px;
  p{
    line-height: 1.5;
  }
}

.info-container {
  display: flex;
  justify-content: space-between; /* Alinea los elementos principales a los extremos */
  align-items: center;
  gap: 10px; /* Espaciado entre el teléfono y los íconos */
}

.info-item {
  display: flex;
  align-items: center;
  gap: 5px; /* Espaciado entre el ícono y el texto */
  text-decoration: none; /* Evita subrayado en enlaces */
  color: inherit; /* Conserva el color del texto */
}

.info-item:first-child {
  margin-right: auto; /* Empuja el teléfono hacia la izquierda */
  i{
    font-size: 1em;
    margin-top: -2px;
  }
}

.info-item i {
  font-size: 1.2em; /* Ajusta el tamaño de los íconos */
  margin-right: 5px; /* Espaciado entre el ícono y el texto */
}

/* Aplica un color específico a los íconos de la derecha */
.info-container > a i {
  color: $theme-color; /* Cambia este valor al color que prefieras */
}

/* Opcional: Cambia el color al pasar el mouse */
.info-container > a:hover i {
  color: $bg-purple; /* Cambia el color al pasar el ratón */
}