@media #{$xs-layout} {
    .search-area.pb-100 {
        padding-bottom: 45px;
    }
}

.search-area {
    .container-fluid {
        padding: 0 70px;

        @media #{$xl-layout} {
            padding: 0 15px;
        }

        @media #{$lg-layout} {
            padding: 0 30px;
        }

        @media #{$md-layout} {
            padding: 0 40px;
        }

        @media #{$xs-layout} {
            padding: 0 15px;
        }
    }
}

.search-page {
    .search-bar-container {
        position: relative;
        padding: 0px;
        background: #ffffff none repeat scroll 0 0;
        box-shadow: none;
        margin-top: -7px;
        form {
            position: relative;
            img {
                position: absolute;
                width: 25px;
                height: 25px;
                right: 70px;
                top: 11px;
                opacity: 0;
                visibility: hidden;
                cursor: pointer;
            }
            img.active {
                opacity: 1;
                visibility: visible;
            }
            input {
                width: 100%;
                padding: 5px 72px 5px 15px;
                transition: all 0.4s ease 0s;
                color: #000;
                border: 1px solid #d3d3d3;
                outline: medium none;
                background: transparent;
            }
            input.active {
                width: 300px;
                /* Tamaño al hacer hover o focus */
            }
            .button-search {
                font-size: 20px;
                line-height: 1;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                padding: 5px 20px 2px;
                transition: all 0.4s ease 0s;
                text-transform: uppercase;
                color: #ffffff;
                border: none;
                border-color: #ce9634;
                background: $theme-color;
                &:hover {
                    background-color: #000;
                }
            }
        }
    }

    .search-results {
        position: relative;
        background-color: white;
        height: auto;
        overflow-y: auto;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
        .tabs{
            li{
                cursor: pointer;
            }
        }
        ul {
            width: calc(100% - 30px);
            list-style: none;
            margin: 0 auto;
            padding: 0;
            li {
                padding: 10px 17px;
                border-bottom: 1px solid #e5e5e5;
                span {
                    position: absolute;
                    font-size: 11px;
                    color: #fff;
                    line-height: 12px;
                    padding: 1px 4px;
                    border-radius: 10px;
                    background-color: $theme-color;
                    margin-left: 2px;
                }
                &.active {
                    border-bottom: 1px solid $theme-color;
                    color: $theme-color;
                }
            }
            li:last-child {
                border-bottom: none;
            }
            .search-container {
                height: 0px;
                transition: height 0.2s;
                overflow: hidden;
                li{
                    cursor: default;
                }
                h1 {
                    font-size: 18px;
                }
                p {
                    font-size: 14px;
                }
                &.active {
                    height: auto;
                    transition: height 0.2s;
                    overflow: auto;
                }
                &.no-results {
                    height: 110px;
                    transition: height 0.2s;
                    overflow: hidden;
                    li {
                        &:hover {
                            background-color: white;
                        }
                    }
                }
                .search-data {
                    border: none;
                    padding: 15px 10px 15px 10px;
                    margin: 10px auto;
                    border-radius: 10px;
                    border-bottom: 1px solid #e5e5e5;
                    padding-bottom: 35px;
                    .search-data-container {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        height: auto;
                        img {
                            padding-top: 5px;
                            max-width: 40%;
                            height: 40%;
                        }
                        .search-data-text {
                            display: flex; /* Activa flexbox */
                            flex-direction: column; /* Ordena los elementos en una columna */
                            justify-content: center; /* Centra verticalmente */
                            align-items: left; /* Centra horizontalmente */
                            width: 60%;
                            padding-left: 30px;
                            
                            h1 {
                                font-weight: 600;
                                margin: 0px;
                                padding: 0px;
                                color: #7b7b7b;
                            }
                        
                            p {
                                color: #7b7b7b;
                            }
                        }
                    }

                    .search-details {
                        font-style: italic;
                        font-weight: 500;
                        color: $theme-color !important;
                    }
                    .search-details-button{
                        background: $theme-color;
                        border: none;
                        color: white;
                        box-shadow: none;
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
}